exports.googleApi = "https://maps.googleapis.com/maps/api/js?v=3&key=AIzaSyAyfVnxQL9gRDT1v-Ii99ANum4J6rr2sXE&libraries=geometry,places&sensor=false&language=en";
exports.gaodeApi = "https://map.qq.com/api/gljs?v=2.exp&libraries=service,geometry&key=KNGBZ-NWDCH-IKJDF-WGVX2-JGSMO-WZFYC";
exports.wsApi = "https://static.xcharger.net/WeChat_C6/js/xcharge.websocket.sdk.v2.js";
exports.wsEuApi = "https://eu-static.xcharger.net/js/eu_push_sdk.js";
exports.product_area="cn";
// exports.requestUrl = "http://localhost:8090/api";
exports.requestUrl = "/api";
//exports.requestUrl = "http://xcloud2.dev.xcharger.net/service/api";
exports.bindWxUrl = "https://wechat.xcharger.net/wechat/auth/v2.0?redirectUrl=https://service.xcharger.net/api/wechatoa/bind";
exports.activateUrl ="http://wechat.xcharger.net/activate/toCheck?oasourceid=gh_cf544c25844a";
exports.operator = "智充";
exports.record = "京ICP备15038975号-1";
